import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'linaria';
import { Action, Menu } from 'mediasoil-shared-components/components/';
import CTA from '../cta';
import Trees from '../trees';
import TwitterIcon from 'Img/twitter.svg';
import FacebookIcon from 'Img/facebook.svg';
import LinkedInIcon from 'Img/linkedin.svg';
import InstagramIcon from 'Img/instagram.svg';
import YoutubeIcon from 'Img/youtube.svg';

const socialnav = {
  twitter: [`#${TwitterIcon.id}`, (v) => `https://twitter.com/${v}`],
  facebook: [`#${FacebookIcon.id}`, (v) => `https://www.facebook.com/${v}`],
  linkedin: [`#${LinkedInIcon.id}`, (v) => `https://www.linkedin.com/${v}`],
  instagram: [`#${InstagramIcon.id}`, (v) => `http://instagram.com/${v}`],
  youtube: [`#${YoutubeIcon.id}`, (v) => `http://youtube.com/${v}`],
};

const styles = {
  footer: css`
    background: map-get(helpers.$colors, brand-orange);
    display: flex;
    flex-direction: column;
    margin-top: helpers.$gutter;
    padding: helpers.$gutter * 3 0 helpers.$gutter;
    position: relative;

    &::before {
      @include helpers.bleed();

      content: '';
    }
  `,
  // Top Section
  sectionTop: css`
    position: relative;

    @include helpers.mq(footer-section-top) {
      align-items: center;
      display: flex;
      justify-content: center;
      width: 100%;
    }
  `,
  headingCnt: css`
    @include helpers.mq(footer-section-top) {
      padding: 0 2em;
    }
  `,
  heading: css`
    font-size: map-get(helpers.$font-sizes, h3);
    margin: 0;
    position: relative;

    @include helpers.mq(footer-section-top) {
      font-size: map-get(helpers.$font-sizes, h2);
      white-space: nowrap;
    }

    sup {
      display: block;
      font-size: map-get(helpers.$font-sizes, small);
      line-height: 1;
      position: static;
    }

    br {
      @include helpers.mq(footer-section-top, true) {
        display: none;
      }
    }
  `,
  trees: css`
    float: right;
    font-size: 0.7em;
    overflow: visible;
    padding-left: helpers.$gutter;
    position: relative;
    transform: translateY(-#{helpers.$gutter});
    width: 35%;

    @include helpers.mq(footer-section-top) {
      flex: 0 1 auto;
      float: none;
      left: -8em;
      padding: 0;
      transform: none;
      width: auto;
    }
  `,
  cta: css`
    display: inline-block;
    font-size: map-get(helpers.$font-sizes, input);
    margin: helpers.$gutter 0;
    max-width: 10em;

    @include helpers.mq(footer-section-top) {
      grid-column: 2;
      grid-row: 2;
    }
  `,
  // Middle Section
  sectionMiddle: css`
    margin: helpers.$gutter * 2 0;

    ol {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    .level0 {
      $gap: helpers.$gutter/2;
      $titleSpacing: 0.75em;

      display: flex;
      flex-wrap: wrap;
      margin: $gap * -1 0 0 $gap * -1;
      width: calc(100% + #{$gap});

      > li {
        flex: 1 1 25%;
        min-width: 12.5em;
        padding: $gap 0 0 $gap;

        > a {
          display: inline-block;
          font-weight: bold;
          letter-spacing: 0.1em;
          margin: 0 0 $titleSpacing;
          text-transform: uppercase;
        }

        > ol {
          margin: 0 0 $titleSpacing;
        }
      }
    }

    .level1 {
      li {
        margin-top: 1em;

        &:first-child {
          margin-top: 0;
        }
      }
    }
  `,
  //
  sectionBottom: css`
    display: flex;
    flex-direction: column-reverse;

    @include helpers.mq(footer-section-bottom) {
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
    }
  `,
  copyright: css`
    margin: 0;
  `,
  social: css`
    align-self: center;

    @include helpers.mq(footer-section-bottom, true) {
      margin-top: helpers.$gutter * -1.5;
    }

    ol {
      display: inline-flex;
      list-style: none;
      margin: 0 auto;
      padding: 0;

      @include helpers.mq(footer-section-bottom, true) {
        flex-wrap: wrap;
        margin: 0 auto helpers.$gutter;
      }
    }

    li {
      flex: 1 1 calc(50% - #{helpers.$gutter});
      margin: helpers.$gutter/2;

      @include helpers.mq(footer-section-bottom) {
        flex: 0 0 auto;
        margin: 0 0 0 helpers.$gutter;
      }
    }

    svg {
      height: 1.5em;
      width: 1.5em;
    }
  `,
};

const Footer = ({ menu, siteTitle, social }) => {
  return (
    <footer className={styles.footer}>
      <div className={styles.sectionTop}>
        <div className={styles.trees}>
          <Trees isForest={true} />
        </div>
        <div className={styles.headingCnt}>
          <h2 className={styles.heading}>
            <sup>So what's next?</sup>
            We'd love to meet you! <br /> Let's talk!
          </h2>
          <CTA className={styles.cta} href="/contact-us/">
            Contact Us
          </CTA>
        </div>
      </div>
      <nav className={styles.sectionMiddle}>
        <Menu links={menu} />
      </nav>
      <div className={styles.sectionBottom}>
        <p className={styles.copyright}>
          &copy; {new Date().getFullYear()} {siteTitle}
        </p>
        <nav className={styles.social}>
          <ol>
            {Object.entries(social).map(
              ([key, value]) =>
                value &&
                socialnav[key] && (
                  <li key={key}>
                    <Action href={socialnav[key][1](value)} target="social">
                      <svg>
                        <use xlinkHref={socialnav[key][0]} />
                      </svg>
                    </Action>
                  </li>
                )
            )}
          </ol>
        </nav>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  siteTitle: PropTypes.string,
  social: PropTypes.object,
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      cssClasses: PropTypes.array.isRequired,
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      links: PropTypes.array,
      target: PropTypes.string,
      title: PropTypes.string,
    })
  ).isRequired,
};

export default Footer;
