import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import SEO from 'mediasoil-shared-components/gatsby/components/seo';

export default ({ link, ...props }) => {
  const {
    site: { siteMetadata },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const linksWithGoogleFont = (link || []).concat([
    {
      rel: 'stylesheet',
      href:
        'https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,700;1,400&subset=latin&display=swap',
    },
  ]);

  return <SEO siteMetadata={siteMetadata} link={linksWithGoogleFont} {...props} />;
};
