import axios, { CancelToken } from 'axios';

const request = axios.create({
  // baseURL: 'http://localhost:5001/mediasoil-com/us-central1',
  baseURL: 'https://us-central1-mediasoil-com.cloudfunctions.net',
  timeout: 5000,
});

export default {
  contact: ({ target }, data, url) => {
    const { token, cancel } = CancelToken.source();

    return [
      request({
        url,
        data: {
          ...data,
          time_submitted: Date.now(),
        },
        method: target.method,
        cancelToken: token,
      }),
      cancel,
    ];
  },
};
