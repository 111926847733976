import React, { useState, useEffect } from 'react';
import { globalHistory } from '@reach/router';
import PropTypes from 'prop-types';
import { css } from 'linaria';
import { Action, Menu } from 'mediasoil-shared-components/components/';
import toggle from 'mediasoil-shared-components/components/menu/toggle';
import Logo from 'Img/logo.svg';
import CaretIcon from 'Img/caret.svg';
import WebDesignIcon from 'Img/service-webdesign.svg';
import WebManagementIcon from 'Img/service-management.svg';
import HostingIcon from 'Img/service-hosting.svg';
import SocialIcon from 'Img/service-social.svg';
import SEOIcon from 'Img/service-seo.svg';

const styles = {
  headerStyles: css`
    align-items: center;
    background: white;
    display: flex;
    height: helpers.$mobileHeaderHeight;
    justify-content: space-between;
    left: 0;
    padding: 0 helpers.$gutter;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: map-get(helpers.$z, header);

    @include helpers.mq(header) {
      height: helpers.$headerHeight;
      padding: 0;
      position: relative;
    }

    &::before {
      @include helpers.bleed();
      content: '';
    }
  `,
  headerClickOff: css`
    background: map-get(helpers.$colors, modal);
    display: block;
    height: calc(100% - #{helpers.$mobileHeaderHeight});
    left: 0;
    opacity: var(--showMobileMenuBool);
    pointer-events: none;
    position: fixed;
    top: helpers.$mobileHeaderHeight;
    transition: opacity map-get(helpers.$transition, menu), visbility 0s linear 350ms;
    visibility: hidden;
    width: 100%;
    z-index: map-get(helpers.$z, hidden);

    #menu:checked ~ * & {
      pointer-events: all;
      visibility: visible;
    }

    @include helpers.mq(header) {
      display: none;
    }
  `,
  logoStyles: css`
    width: 17rem;

    @include helpers.mq(header) {
      width: 29rem;
    }
  `,
  logoSvgStyles: css`
    max-height: helpers.$mobileHeaderHeight;
    width: 100%;

    @include helpers.mq(header) {
      max-height: helpers.$headerHeight;
    }
  `,
  mobileMenuLabel: css`
    cursor: pointer;
    display: inline-block;
    font-weight: bold;
    height: 100%;
    letter-spacing: 0.1em;
    line-height: helpers.$mobileHeaderHeight;
    text-transform: uppercase;

    @include helpers.mq(header) {
      display: none;
    }

    &::before {
      content: 'Menu';

      #menu:checked ~ * & {
        content: 'Close';
      }
    }
  `,
  navStyles: css`
    background: white;
    color: currentColor;
    display: flex;
    flex-direction: column;
    height: calc(100% - #{helpers.$mobileHeaderHeight});
    justify-content: space-between;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
    position: fixed;
    right: 0;
    top: helpers.$mobileHeaderHeight;
    transform: translateX(calc(100% * (1 - var(--showMobileMenuBool))));
    transition: all map-get(helpers.$transition, menu);
    visibility: hidden;
    width: 23.4375em;

    @include helpers.mq(header, true) {
      #menu:checked ~ * & {
        visibility: visible;
      }
    }

    @include helpers.mq(header) {
      align-items: center;
      flex-direction: row;
      height: 100%;
      justify-content: flex-end;
      overflow: visible;
      position: static;
      transform: none;
      visibility: visible;
      width: 100%;
    }

    &::after {
      background: url('/mobile-menu-v1.svg') no-repeat;
      background-size: contain;
      content: '';
      display: block;
      padding-bottom: 75.2%;
      width: 100%;

      @include helpers.mq(header) {
        content: normal;
      }
    }
  `,
  menu: css`
    list-style: none;
    margin: 0;
    overflow: visible;
    padding: helpers.$gutter/2 helpers.$gutter * 2;

    @include helpers.mq(header) {
      display: flex;
      padding-right: 0;
    }

    ol {
      height: 0;
      list-style: none;
      margin: 0;
      overflow: hidden;
      padding: 0;
      visibility: hidden;

      @include helpers.mq(header) {
        background: white;
        display: flex;
        justify-content: flex-end;
        left: 0;
        overflow: visible;
        position: absolute;
        top: 0;
        transition: top map-get(helpers.$transition, default);
        width: 100%;
        z-index: map-get(helpers.$z, hidden) - 1;

        &::after {
          @include helpers.bleed();

          border-bottom: 2px solid map-get(helpers.$colors, secondary);
          content: '';
        }
      }

      ol {
        display: none;
      }
    }

    [aria-expanded='true'] ~ ol {
      height: auto;
      visibility: visible;

      @include helpers.mq(header) {
        top: 100%;
        z-index: map-get(helpers.$z, hidden);
      }
    }

    > li {
      @include helpers.mq(header) {
        align-items: center;
        margin-left: helpers.$gutter * 2;

        &:first-child {
          margin-left: 0;
        }
      }
    }

    li {
      padding: helpers.$gutter/2 0;
      width: calc(100% + #{helpers.$gutter * 2});

      @include helpers.mq(header) {
        display: flex;
        width: auto;
      }
    }

    .level1 > li {
      @include helpers.mq(header, true) {
        padding-left: helpers.$gutter;
        padding-right: helpers.$gutter * 3;

        &:first-child {
          border-top: 2px solid map-get(helpers.$colors, primary);
          margin-top: helpers.$gutter/2;
          padding-top: helpers.$gutter;
        }

        &:last-child {
          border-bottom: 2px solid map-get(helpers.$colors, primary);
          padding-bottom: helpers.$gutter;
        }
      }

      @include helpers.mq(header) {
        flex: 1;
        margin: 0 helpers.$gutter/2;
        max-width: 16rem;
        padding-top: 0;
        width: 100%;

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    a {
      cursor: pointer;
      display: inline-block;
      font-weight: bold;
      letter-spacing: 0.1em;
      text-transform: uppercase;
    }

    & > li > a {
      @include helpers.mq(header) {
        position: relative;

        &[data-partially-current] {
          &::before {
            background: map-get(helpers.$colors, primary);
            content: '';
            height: 0.625em;
            left: 0;
            pointer-events: none;
            position: absolute;
            top: helpers.$headerHeight/-2;
            transform: translateY(100%);
            width: 100%;
          }
        }
      }
    }

    .level1 a {
      @include helpers.mq(header) {
        align-items: center;
        border-radius: 1em;
        display: flex;
        font-size: map-get(helpers.$font-sizes, h6);
        min-height: 12rem;
        padding: helpers.$gutter;
        position: relative;
        transition: all map-get(helpers.$transition, default);
        width: 100%;

        span {
          flex: 1;
          padding-right: calc(2.5em - #{helpers.$gutter/2});
          position: relative;
        }
      }
    }

    .level1 li:nth-child(odd) a {
      @include helpers.mq(header) {
        background: map-get(helpers.$colors, primary);
        color: map-get(helpers.$colors, primaryColor);

        &:hover,
        &:focus {
          background: rgba(map-get(helpers.$colors, primary), 0.2);
          color: rgba(map-get(helpers.$colors, action1), 0.4);
        }
      }
    }

    .level1 li:nth-child(even) a {
      @include helpers.mq(header) {
        background: map-get(helpers.$colors, secondary);
        color: map-get(helpers.$colors, primary);

        &:hover,
        &:focus {
          background: rgba(map-get(helpers.$colors, secondary), 0.2);
          color: rgba(map-get(helpers.$colors, action1), 0.4);
        }
      }
    }
  `,
  anchorCaret: css`
    color: map-get(helpers.$colors, action3);
    display: inline-block;
    font-size: 1.2em;
    height: 0.5em;
    margin: -0.15em 0 0 0.5em;
    pointer-events: none;
    width: 0.75em;

    [aria-expanded='true'] & {
      transform: rotate(180deg);
    }
  `,
  serviceIcon: css`
    height: 2.5em;
    position: absolute;
    right: helpers.$gutter/2;
    top: helpers.$gutter/2;
    width: 2.5em;

    @include helpers.mq(header, true) {
      display: none;
    }

    ~ * {
      top: helpers.$gutter/4;
    }

    .social & {
      width: 3em;
    }
  `,
};

const icons = {
  design: '#' + WebDesignIcon.id,
  management: '#' + WebManagementIcon.id,
  hosting: '#' + HostingIcon.id,
  social: '#' + SocialIcon.id,
  seo: '#' + SEOIcon.id,
};

const Label = toggle('menu', true)[1];

const Header = ({ menu, siteTitle = '' }) => {
  const [expandedTable, setInExpandedTable] = useState({});
  const setIsExpanded = (evt, id) => {
    evt.preventDefault();
    setInExpandedTable({ [id]: !expandedTable[id] });
  };
  const closeEverything = () => {
    document.getElementById('menu').checked = false;
    setInExpandedTable({});
  };

  useEffect(() => {
    globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        closeEverything();
      }
    });
  }, []);

  return (
    <header
      className={styles.headerStyles}
      onKeyUp={({ key, keyCode }) => {
        if (key === 'Escape' || keyCode === 27) {
          closeEverything();
        }
      }}
    >
      <div className={styles.headerClickOff} onClick={closeEverything}></div>
      <Action className={styles.logoStyles} href="/" title={siteTitle} onClick={closeEverything}>
        <svg className={styles.logoSvgStyles}>
          <use xlinkHref={`#${Logo.id}`} />
        </svg>
      </Action>
      <Label className={styles.mobileMenuLabel} />
      <nav className={styles.navStyles}>
        <Menu
          className={styles.menu}
          context={[expandedTable, setIsExpanded]}
          links={menu}
          beforeAction={(hasLinks, isSubMenu, { label, cssClasses }) => {
            if (isSubMenu && cssClasses.length) {
              const icon = icons[cssClasses[0]];

              if (icon)
                return (
                  <svg className={styles.serviceIcon}>
                    <use xlinkHref={icon} />
                  </svg>
                );
            }

            return null;
          }}
          afterAction={(hasLinks) => {
            if (hasLinks) {
              return (
                <svg className={styles.anchorCaret}>
                  <use xlinkHref={`#${CaretIcon.id}`} />
                </svg>
              );
            }

            return null;
          }}
        />
      </nav>
      <section key="toast" id="toast"></section>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
  menu: PropTypes.arrayOf(
    PropTypes.shape({
      cssClasses: PropTypes.array.isRequired,
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      links: PropTypes.array,
      target: PropTypes.string,
      title: PropTypes.string,
    })
  ).isRequired,
};

export default Header;
