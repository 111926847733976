import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'linaria';
import { Action } from 'mediasoil-shared-components/components/';
import cls from 'mediasoil-shared-components/utils/class_strings';

const styles = {
  base: css`
    border-radius: 2em;
    cursor: pointer;
    font-weight: bold;
    letter-spacing: 0.15em;
    min-width: 18.75em;
    padding: 1em;
    text-align: center;
    text-transform: uppercase;
  `,
  primary: css`
    background: map-get(helpers.$colors, primary);
    color: map-get(helpers.$colors, primaryColor);

    &:hover,
    &:focus,
    &:active {
      color: darken(map-get(helpers.$colors, secondary), 15);
    }
  `,
  secondary: css`
    background: map-get(helpers.$colors, secondary);
    color: map-get(helpers.$colors, primary);

    &:hover,
    &:focus,
    &:active {
      color: darken(map-get(helpers.$colors, primary), 30);
    }
  `,
};

const CTA = ({ style = 'primary', className, ...props }) => {
  return <Action className={cls(className, styles.base, styles[style])} {...props} />;
};

CTA.propTypes = {
  style: PropTypes.string,
};

export default CTA;
