/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import format_menu from 'mediasoil-shared-components/gatsby/utils/format_menu';
import Header from '../header';
import Footer from '../footer';
import { WindowProvider } from '../window';
import './layout.scss';

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          generalSettings {
            facebook
            linkedin
            instagram
            twitter
            youtube
          }
        }
      }
      mediasoil {
        menus(first: 1000) {
          nodes {
            name
            location
            menuItems(first: 1000) {
              nodes {
                cssClasses
                label
                target
                title
                url
                id
                parentId
                childItems(first: 1000) {
                  nodes {
                    cssClasses
                    label
                    target
                    title
                    url
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const { title, generalSettings } = data.site.siteMetadata;
  const { menus } = data.mediasoil;
  const { FOOTER_MENU, MAIN_MENU } = format_menu(menus);

  return (
    <WindowProvider>
      <Header menu={MAIN_MENU?.links} siteTitle={title} />
      {children}
      <Footer menu={FOOTER_MENU?.links} siteTitle={title} social={generalSettings} />
    </WindowProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
