import React from 'react';
import { css } from 'linaria';
import cls from 'mediasoil-shared-components/utils/class_strings';

const style = {
  base: css`
    $size: 18%;

    min-height: 10em;
    min-width: 10em;

    div {
      height: 100%;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: $size;

      &::before,
      &::after {
        animation: loader 0.8s ease infinite;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.592 9.593'%3E%3Cpath d='M7.804 7.805C5.248 10.362.167 9.426.167 9.426S-.77 4.344 1.786 1.788C4.343-.768 9.424.168 9.424.168s.938 5.08-1.62 7.637' fill='%23#{str-slice(inspect(map-get(helpers.$colors, brand-green)), 2)}'/%3E%3C/svg%3E");
        background-size: contain;
        border-radius: 50%;
        content: '';
        display: block;
        height: $size;
        left: 0;
        position: absolute;
        top: 0;
        transform: rotate(180deg);
        width: 100%;
      }

      &::after {
        bottom: 0;
        top: auto;
      }

      &:nth-child(1) {
        &::after {
          animation-delay: 0.4s;
        }
      }

      &:nth-child(2) {
        transform: translate(-50%, -50%) rotate(45deg);

        &::before {
          animation-delay: 0.1s;
        }

        &::after {
          animation-delay: 0.5s;
        }
      }

      &:nth-child(3) {
        transform: translate(-50%, -50%) rotate(90deg);

        &::before {
          animation-delay: 0.2s;
        }

        &::after {
          animation-delay: 0.6s;
        }
      }

      &:nth-child(4) {
        transform: translate(-50%, -50%) rotate(135deg);

        &::before {
          animation-delay: 0.3s;
        }

        &::after {
          animation-delay: 0.7s;
        }
      }
    }
  `,
  absolute: css`
    position: absolute;
  `,
  relative: css`
    position: relative;
  `,
};

export default ({ className, position = 'absolute', ...props }) => (
  <div className={cls(className, style.base, style[position])} {...props}>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);
