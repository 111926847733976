import React, { useEffect, useState, useContext } from 'react';
import { css } from 'linaria';
import { Accordion } from 'mediasoil-shared-components/components/';
import cls from 'mediasoil-shared-components/utils/class_strings';
import WindowContext from '../window';

const style = css`
  border: solid map-get(helpers.$colors, action1);
  border-width: 1px 0;
  padding: helpers.$gutter 0;

  > summary:first-child {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: map-get(helpers.$font-sizes, h4);
    outline-color: map-get(helpers.$colors, action1);
    outline-width: 1px;

    &::before {
      background: map-get(helpers.$colors, action1)
        url("data:image/svg+xml,%3Csvg viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22.25 18.75h7a1.75 1.75 0 110 3.5h-7v7a1.75 1.75 0 11-3.5 0v-7h-7a1.75 1.75 0 110-3.5h7v-7a1.75 1.75 0 113.5 0v7z' fill='%23#{str-slice(inspect(map-get(helpers.$colors, primaryColor)), 2)}'/%3E%3C/svg%3E")
        center no-repeat;
      background-size: contain;
      border-radius: 50%;
      content: '';
      display: inline-block;
      height: 1em;
      margin-right: 0.5em;
      width: 1em;
    }

    ~ * {
      margin: 1em 0;
      padding: 0 1em;
    }
  }

  &[open] > summary:first-child::before {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40'%3E%3Cpath d='M29.25 18.25h-17.5a1.75 1.75 0 100 3.5h17.5a1.75 1.75 0 100-3.5z' fill='%23#{str-slice(inspect(map-get(helpers.$colors, primaryColor)), 2)}'/%3E%3C/svg%3E");
  }

  & + & {
    margin-top: -1px;
  }
`;

export default ({ className, open, ...props }) => {
  // const { hash } = useContext(WindowContext);
  // const { id } = props;
  // const [setIsOpen, triggerUpdate] = useState(undefined);
  // let isOpen = hash === id;

  // console.log({ open });

  // useEffect(() => {
  //   triggerUpdate(1);
  //   setTimeout(() => triggerUpdate(undefined));
  // }, [hash, id]);

  const setIsOpen = undefined;
  const isOpen = open;

  return <Accordion className={cls(className, style)} open={isOpen} setIsOpen={setIsOpen} {...props} />;
};
