import React from 'react';
import replaceContent from 'html-react-parser';
import { css } from 'linaria';
import cls from 'mediasoil-shared-components/utils/class_strings';
import { decode_for_json } from 'mediasoil-shared-components/utils/encode_for_json';

const style = {
  ol: css`
    border-top: 2px solid map-get(helpers.$colors, brand-green);
    display: grid;
    grid-column-gap: 2px;
    grid-template-columns: 1fr;
    list-style: none;
    padding: 0;
    position: relative;

    @include helpers.mq(service-squares, true) {
      margin: helpers.$gutter helpers.$gutter * -1 !important; /* stylelint-disable-line declaration-no-important */
    }

    @include helpers.mq(service-squares) {
      grid-template-columns: 1fr 1fr;
      margin: helpers.$gutter auto;

      &::before {
        background: map-get(helpers.$colors, brand-green);
        content: '';
        height: 100%;
        left: 50%;
        position: absolute;
        top: 0;
        transform: translateX(-50%);
        width: 2px;
      }
    }
  `,
  li: css`
    border-bottom: 2px solid map-get(helpers.$colors, brand-green);
    display: flex;
    margin: 0;

    @include helpers.mq(service-squares) {
      &::before {
        content: '';
        display: inline-block;
        padding-bottom: 100%;
      }
    }

    @include helpers.mq(service-squares) {
      &:nth-child(4n + 1) {
        background: url("data:image/svg+xml,%3Csvg viewBox='0 0 120 120' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='120' width='120' height='120' rx='60' transform='rotate(90 120 0)' fill='%23FFB037'/%3E%3C/svg%3E")
          left bottom no-repeat;
        background-size: 15% 15%;
      }

      &:nth-child(4n + 3) {
        background: url("data:image/svg+xml,%3Csvg viewBox='0 0 120 120' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 120V0h120c0 66.274-53.726 120-120 120z' fill='%23D3FFBF'/%3E%3C/svg%3E")
          left top no-repeat;
        background-size: 15% 15%;
      }

      &:nth-child(4n + 4) {
        background: url("data:image/svg+xml,%3Csvg viewBox='0 0 260 133' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M65 133c35.899 0 65-29.101 65-65 0-35.898-29.101-65-65-65C29.102 3 0 32.102 0 68c0 35.899 29.102 65 65 65z' fill='%23FFB037'/%3E%3Cpath d='M260 130V0H130v130h130z' fill='%2371B52C'/%3E%3C/svg%3E")
          right 111% no-repeat;
        background-size: 30% 30%;
      }
    }
  `,
  cnt: css`
    padding: helpers.$gutter * 4 helpers.$gutter * 2 helpers.$gutter * 2;

    @include helpers.mq(service-squares) {
      align-self: center;
      padding: 10%;
    }

    *:last-child {
      margin-bottom: 0;
    }
  `,
  title: css`
    font-size: map-get(helpers.$font-sizes, h5);
    position: relative;
    text-transform: uppercase;

    &::before {
      background: url("data:image/svg+xml,%3Csvg viewBox='0 0 57 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16 15.915V18a4 4 0 01-8 0v-2.085c0-.195-.07-.408-.233-.689a6.665 6.665 0 00-.449-.652l-.07-.091c-.138-.18-.44-.574-.552-.725C5.566 12.232 5 10.87 5 9a7 7 0 0114 0c0 1.91-.574 3.325-1.694 4.848-.12.161-.419.55-.596.781l-.134.175a4.609 4.609 0 00-.459.7.938.938 0 00-.117.411zm-1.956-.476c.05-.276.146-.548.28-.82.158-.32.358-.625.653-1.017l.17-.22v-.001c.18-.234.447-.581.547-.718C16.583 11.455 17 10.428 17 9A5 5 0 007 9c0 1.379.407 2.357 1.304 3.568.096.13.368.485.515.676l.094.124c.252.332.43.589.583.852.238.41.397.808.465 1.222A3.872 3.872 0 0012 16c.744 0 1.42-.185 2.044-.56zM14 18v-.338c-.633.225-1.3.338-2 .338s-1.367-.113-2-.338V18a2 2 0 104 0zM46 19v-1.07a6.999 6.999 0 004.781-2.982C53.495 14.574 55 12.223 55 9V6a2 2 0 00-2-2h-1.268A2 2 0 0050 3H40a2 2 0 00-1.732 1H37a2 2 0 00-2 2v3c0 3.223 1.505 5.574 4.219 5.948A6.999 6.999 0 0044 17.929V19h-1a2 2 0 00-2 2h8a2 2 0 00-2-2h-1zM37 6h1v5c0 .458.044.905.128 1.338C37.39 11.675 37 10.531 37 9V6zm15 0v5c0 .458-.044.905-.128 1.338C52.61 11.675 53 10.531 53 9V6h-1zm-12 5V5h10v6a5 5 0 01-10 0z' fill='%2371B52C'/%3E%3C/svg%3E")
        left center no-repeat;
      content: '';
      height: 1.5em;
      left: -0.15em;
      position: absolute;
      top: 0;
      transform: translateY(-150%);
      width: 4em;
    }
  `,
  content: css`
    font-size: map-get(helpers.$font-sizes, h5);
    padding-top: 1.25em;
  `,
};

export default ({ className, ...props }) => {
  const json = JSON.parse(decode_for_json(props['data-json']));

  return (
    <ol className={cls(className, style.ol)}>
      {json.map(({ id, title, content }) => (
        <li className={style.li} key={id}>
          <div className={style.cnt}>
            <strong className={style.title}>{title.rendered}</strong>
            <div className={style.content}>{replaceContent(content.rendered)}</div>
          </div>
        </li>
      ))}
    </ol>
  );
};
