import { css } from 'linaria';

export default css`
  margin-bottom: helpers.$gutter;

  > span {
    display: block;
    font-size: map-get(helpers.$font-sizes, input);
  }

  textarea,
  input,
  select {
    border: 1px solid map-get(helpers.$colors, action1);
    border-radius: 0.5em;
    font-size: map-get(helpers.$font-sizes, input);
    padding: helpers.$gutter/2;
    transition: background map-get(helpers.$transition, default);
    width: 100%;

    &:focus {
      background: map-get(helpers.$colors, secondary);
      font-weight: 700;
      outline: 0;
    }

    + strong {
      background-position: left center;
      background-repeat: no-repeat;
      background-size: 1.5em;
      display: block;
      font-size: map-get(helpers.$font-sizes, input);
      font-weight: normal;
      margin: 0.5em 0;
    }

    &:invalid + strong {
      background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M22.441 15.953L15.436 3.978a3.974 3.974 0 00-6.873 0l-7.008 11.98a4.007 4.007 0 003.419 6.04h14.038a4.005 4.005 0 003.43-6.045zm-19.163 1.02l7.013-11.988a1.975 1.975 0 013.416-.003l7.01 11.985a2.007 2.007 0 01-1.715 3.031H4.982a2.004 2.004 0 01-1.704-3.024zM12 17.999a1 1 0 100-2 1 1 0 000 2zm1.004-10h-2.001v7h2v-7z' fill='%23#{str-slice(inspect(map-get(helpers.$colors, error)), 2)}'/%3E%3C/svg%3E");
      color: map-get(helpers.$colors, error);
      padding-left: 1.75em;
    }
  }
`;
