import React from 'react';
import { css } from 'linaria';
import { format } from 'date-fns';
import { decode_for_json } from 'mediasoil-shared-components/utils/encode_for_json';
import CTA from '../cta';

const style = {
  ol: css`
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;

    @include helpers.mq(latestposts, true) {
      flex-direction: column;
    }

    li {
      margin: helpers.$gutter 0;

      @include helpers.mq(latestposts) {
        margin: helpers.$gutter * 2 0;
      }
    }
  `,
  li: css`
    $curve: helpers.$gutter * 1.5;

    background: map-get(helpers.$colors, complement-green);
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    padding-bottom: helpers.$gutter;
    width: 100%;

    @include helpers.mq(latestposts) {
      max-width: 30rem;

      &:first-child {
        border-radius: 0 0 0 $curve;
      }

      &:last-child {
        border-radius: 0 0 $curve 0;
      }
    }

    time {
      background: map-get(helpers.$colors, complement-green);
    }

    &:nth-child(even) {
      background-color: map-get(helpers.$colors, brand-green);

      @include helpers.mq(latestposts) {
        margin-left: helpers.$gutter;
        margin-right: helpers.$gutter;
      }

      time {
        background-color: map-get(helpers.$colors, brand-green);
      }
    }
  `,
  imgCnt: css`
    background: white;
    height: 30rem;
    position: relative;
    width: 100%;

    @include helpers.mq(latestposts) {
      height: 40rem;
    }
  `,
  img: css`
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    width: 100%;
  `,
  time: css`
    bottom: 0;
    font-size: map-get(helpers.$font-sizes, h5);
    font-weight: 300;
    line-height: 1;
    padding: helpers.$gutter * 0.75 helpers.$gutter;
    position: absolute;
    right: 0;

    small {
      display: block;
    }
  `,
  title: css`
    @include helpers.fluid(font-size, 37.5em, 62.5em, 1.25em, 2em);

    display: block;
    flex-grow: 1;
    font-weight: 300;
    padding: helpers.$gutter;
    width: 100%;
    word-break: break-word;
  `,
  btn: css`
    display: block;
    margin: 0 auto;
    max-width: calc(100% - #{helpers.$gutter * 2});
    min-width: auto;
    width: 100%;
  `,
};

export default ({ className, ...props }) => {
  const json = JSON.parse(decode_for_json(props['data-json']));

  return (
    <ol className={style.ol}>
      {json.map(({ id, jetpack_featured_media_url: image_url, link, modified, title, ...post }) => {
        const date = new Date(modified);
        const displayMonth = format(date, 'LLL');
        const displayDay = format(date, 'd');

        return (
          <li className={style.li} key={id}>
            <div className={style.imgCnt}>
              {image_url && (
                <img
                  alt={`${title.rendered} Feature`}
                  className={style.img}
                  loading="lazy"
                  src={`${image_url.replace(/fit=\d+%2C\d+&/, '')}&fit=300%2C400`}
                />
              )}
              <time className={style.time} dateTime={modified}>
                <small>{displayMonth}</small>
                {displayDay}
              </time>
            </div>
            <strong className={style.title} dangerouslySetInnerHTML={{ __html: title.rendered }} />
            <CTA className={style.btn} href={link} style="secondary">
              Read
            </CTA>
          </li>
        );
      })}
    </ol>
  );
};
