import React from 'react';
import { HorizontalScroller } from 'mediasoil-shared-components/components/';
import cls from 'mediasoil-shared-components/utils/class_strings';
import { css } from 'linaria';
import { decode_for_json } from 'mediasoil-shared-components/utils/encode_for_json';
import Trees from '../trees';
import CTA from '../cta';
import ArrowIcon from 'Img/arrow.svg';

const style = {
  hs: css`
    margin-top: helpers.$gutter * 2;
    max-width: none !important; /* stylelint-disable-line declaration-no-important */
    quotes: '“' '”';
    width: 100%;

    @include helpers.mq(testimonials) {
      margin-top: helpers.$gutter * 3;
    }
  `,
  // #region page1
  page1: css`
    display: grid;
    grid-template-columns: 41% 32% 27%;
    grid-template-rows: 21.5rem auto auto auto;
    max-width: none;
    overflow: hidden;
    position: relative;

    @include helpers.mq(testimonials, true) {
      grid-template-columns: 25% 48% 27%;
      grid-template-rows: 21.5rem 1fr auto;
    }
  `,
  figure: css`
    height: 100%;
    margin: 0;
    position: relative;
    width: 100%;
  `,
  figure1: css`
    grid-column: 1 / 3;
    grid-row: 1 / 4;

    @include helpers.mq(testimonials, true) {
      grid-column: 1 / 4;
    }
  `,
  img: css`
    filter: grayscale(0.8);
    height: 100%;
    object-fit: cover;
    object-position: left;
    position: absolute;
    width: 100%;
  `,
  banner: css`
    background: map-get(helpers.$colors, brand-green);
    color: white;
    display: flex;
    flex-direction: column;
    grid-column: 2 / 4;
    grid-row: 2;
    justify-content: center;
    margin: 0;
    padding: helpers.$gutter * 2;
    position: relative;
    width: 100%;

    @include helpers.mq(testimonials, true) {
      padding: helpers.$gutter;
    }
  `,
  bannerTitle: css`
    display: block;
    font-weight: normal;
    text-transform: uppercase;

    &::after {
      content: ':';
    }
  `,
  bannerQuote: css`
    @include helpers.fluid(font-size, 48em, 68.75em, 1.5em, 2.25em);

    font-weight: 300;
    letter-spacing: 0.015em;
    margin: helpers.$gutter/2 0 helpers.$gutter 0;
    text-align: right;

    &::before {
      content: open-quote;
    }

    &::after {
      content: close-quote;
    }
  `,
  bannerList: css`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    line-height: 1.15;
    list-style: none;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    width: 100%;

    li {
      margin: 0;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:not(:first-child)::before {
        content: '+';
        padding: 0 0.5em;
      }
    }
  `,
  tree: css`
    max-width: 100%;
    top: 0.25em;
    z-index: map-get(helpers.$z, base);

    @include helpers.mq(testimonials, true) {
      display: none;
    }
  `,
  linkCnt: css`
    background: map-get(helpers.$colors, background);
    grid-column: 3;
    grid-row: 3;
    height: 100%;
    position: relative;
    width: 100%;
  `,
  btn: css`
    background: map-get(helpers.$colors, secondary);
    border-radius: 0;
    color: map-get(helpers.$colors, primary);
    display: block;
    font-weight: bold;
    height: 0;
    max-width: 100%;
    min-width: auto;
    padding: 0 0 50%;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    width: 50%;
    z-index: map-get(helpers.$z, base);

    @include helpers.mq(testimonials, true) {
      height: 6.625em;
      width: 6.625em;
    }
  `,
  btnCnt: css`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  `,
  arrow: css`
    height: 1em;
    margin: 0 auto;
  `,
  arrowRev: css`
    transform: rotate(-180deg);
  `,
  tail: css`
    grid-column: 3;
    grid-row: 4;
    width: 100%;

    @include helpers.mq(testimonials, true) {
      display: none;
    }
  `,
  // #endregion
  // #region page2
  page2: css`
    display: grid;
    grid-template-columns: 27% 27% 1fr;
    grid-template-rows: 21.5rem auto auto auto;
    left: 1px;
    max-width: none;
    overflow: hidden;
    position: relative;
    z-index: map-get(helpers.$z, base);

    @include helpers.mq(testimonials, true) {
      grid-template-columns: 27% 1fr;
      grid-template-rows: 21.5rem auto auto;
    }
  `,
  figure2: css`
    grid-column: 1;
    grid-row: 1 / 3;
  `,
  figure3: css`
    grid-column: 2;
    grid-row: 2 / 4;

    @include helpers.mq(testimonials, true) {
      display: none;
    }
  `,
  banner2: css`
    @include helpers.fluid(padding-left, helpers.$minimumScreenWidth, 48em, helpers.$gutter, helpers.$gutter * 3);
    @include helpers.fluid(padding-right, helpers.$minimumScreenWidth, 48em, 0rem, helpers.$gutter * 3);

    align-self: center;
    grid-column: 2 / 4;
    grid-row: 1;
    padding: helpers.$gutter;

    @include helpers.mq(testimonials, true) {
      grid-column: 2 / 3;
    }
  `,
  bannerQuote2: css`
    margin: 0;
    text-align: left;
  `,
  description: css`
    @include helpers.fluid(
      (padding-left, padding-right),
      helpers.$minimumScreenWidth,
      48em,
      helpers.$gutter,
      helpers.$gutter * 2
    );

    align-self: center;
    display: flex;
    flex-direction: column;
    grid-column: 3;
    grid-row: 2 / 4;

    @include helpers.mq(testimonials, true) {
      align-self: start;
      grid-column: 2 / 3;
      margin-top: 0;
      padding-right: 0;
    }
  `,
  descriptionCnt: css`
    > * {
      text-align: justify;

      &:last-child {
        margin-bottom: 0;
      }

      &::before {
        content: open-quote;
      }

      &::after {
        content: close-quote;
      }
    }
  `,
  url: css`
    align-self: flex-start;
    margin-top: helpers.$gutter * 1.5;
    max-width: 18.75em;
    min-width: auto;
    width: 100%;
  `,
  btnPrev: css`
    grid-column: 1;
    grid-row: 3;
    justify-self: end;
  `,
  // #endregion
};

export default ({ className, ...props }) => {
  const json = JSON.parse(decode_for_json(props['data-json']));

  return (
    <>
      {json.map(({ id, acf, title }) => (
        <HorizontalScroller className={style.hs} hideNav={true} tagName="section" id={`testimonials${id}`} key={id}>
          <div className={style.page1}>
            <figure className={cls(style.figure, style.figure1)}>
              <img alt={acf.main_image.alt} className={style.img} src={acf.main_image.url} loading="lazy" />
            </figure>
            <Trees className={style.tree} isForest={true} />
            <div className={style.banner}>
              <div>
                <strong className={style.bannerTitle}>{title.rendered}</strong>
                <blockquote className={style.bannerQuote}>{acf.quote}</blockquote>
                <ul className={style.bannerList}>
                  {acf.services.map((ser) => (
                    <li key={ser}>{ser}</li>
                  ))}
                </ul>
              </div>
            </div>
            <div className={style.linkCnt}>
              <CTA className={style.btn} href={`#testimonials${id}1`} style="secondary">
                <div className={style.btnCnt}>
                  Next
                  <svg className={style.arrow}>
                    <use xlinkHref={`#${ArrowIcon.id}`} />
                  </svg>
                </div>
              </CTA>
            </div>
            <svg className={style.tail} viewBox="0 0 389 262" aria-hidden="true" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 128V0h126.999v128h-127z" fill="#71B52C" />
              <path d="M0 259V128h127v131H0z" fill="#01714F" />
              <path d="M389 139V0H127v139h262z" fill="#FFB037" />
              <path
                d="M389 131.001c0 72.349-58.65 131-130.999 131-72.35 0-131-58.651-131-131 0-72.35 58.65-131 131-131 72.349 0 131 58.65 130.999 131z"
                fill="#D3FFBF"
              />
              <path
                d="M297.257 77.37v114.364a7.37 7.37 0 01-7.37 7.37h-59.813a7.37 7.37 0 01-7.37-7.37V77.37a7.37 7.37 0 017.37-7.37h59.813a7.37 7.37 0 017.37 7.37z"
                fill="#71B52C"
              />
              <path
                d="M249.948 189.052a1.948 1.948 0 100 3.896h20.291a1.948 1.948 0 000-3.896h-20.291zM266.5 76.052a1.948 1.948 0 100 3.896h5a1.948 1.948 0 100-3.896h-5zM248.5 76.052a1.948 1.948 0 100 3.896h12.448a1.949 1.949 0 100-3.896H248.5z"
                fill="#E4CB94"
              />
              <path
                d="M213.906 130.372a1.949 1.949 0 00-2.756-.007 5.864 5.864 0 00-1.742 4.187 5.87 5.87 0 001.742 4.187 1.946 1.946 0 002.756-.007 1.95 1.95 0 00-.008-2.756 1.996 1.996 0 01-.594-1.424c0-.538.211-1.044.594-1.425a1.948 1.948 0 00.008-2.755zM208.327 125.417a1.95 1.95 0 00-2.755-.007 12.806 12.806 0 00-3.806 9.142c0 3.454 1.351 6.7 3.806 9.142.38.378.877.567 1.374.567a1.949 1.949 0 001.374-3.33 8.937 8.937 0 01-2.658-6.379c0-2.409.944-4.675 2.658-6.379a1.95 1.95 0 00.007-2.756z"
                fill="#01714F"
              />
              <path
                d="M197.896 134.552c0-4.404 1.717-8.544 4.834-11.658a1.948 1.948 0 10-2.754-2.756A20.244 20.244 0 00194 134.552a20.24 20.24 0 005.977 14.414 1.947 1.947 0 102.754-2.756 16.371 16.371 0 01-4.835-11.658zM307.094 130.372a1.949 1.949 0 00.008 2.755c.383.381.594.887.594 1.425 0 .537-.211 1.043-.594 1.424a1.948 1.948 0 102.748 2.762 5.864 5.864 0 001.742-4.187 5.87 5.87 0 00-1.742-4.187 1.95 1.95 0 00-2.756.008zM315.428 125.41a1.949 1.949 0 00-2.748 2.762 8.94 8.94 0 012.658 6.38 8.939 8.939 0 01-2.658 6.379 1.948 1.948 0 102.748 2.762 12.804 12.804 0 003.806-9.141 12.81 12.81 0 00-3.806-9.142z"
                fill="#01714F"
              />
              <path
                d="M321.023 120.138a1.948 1.948 0 10-2.754 2.756 16.371 16.371 0 014.834 11.658c0 4.403-1.717 8.543-4.834 11.658a1.948 1.948 0 102.754 2.756A20.24 20.24 0 00327 134.552a20.24 20.24 0 00-5.977-14.414z"
                fill="#01714F"
              />
            </svg>
          </div>
          <div className={style.page2}>
            <figure className={cls(style.figure, style.figure2)}>
              <img alt={acf.image_2.alt} className={style.img} src={acf.image_2.url} loading="lazy" />
            </figure>
            <figure className={cls(style.figure, style.figure3)}>
              <img alt={acf.image_3.alt} className={style.img} src={acf.image_3.url} loading="lazy" />
            </figure>
            <div className={style.banner2}>
              <blockquote className={cls(style.bannerQuote, style.bannerQuote2)}>{acf.quote_2}</blockquote>
            </div>
            <div className={style.description}>
              <div className={style.descriptionCnt} dangerouslySetInnerHTML={{ __html: acf.description }} />
              {acf.url && (
                <CTA href={acf.url} className={style.url} target="_blank">
                  Website
                </CTA>
              )}
            </div>
            <CTA className={cls(style.btn, style.btnPrev)} href={`#testimonials${id}0`} style="secondary">
              <div className={style.btnCnt}>
                Prev
                <svg className={cls(style.arrow, style.arrowRev)}>
                  <use xlinkHref={`#${ArrowIcon.id}`} />
                </svg>
              </div>
            </CTA>
          </div>
        </HorizontalScroller>
      ))}
    </>
  );
};
