import React, { createContext, useState, useEffect } from 'react';
import { globalHistory } from '@reach/router';

const getHash = () => window.location.hash.slice(1);

const WindowContext = createContext({
  hash: '',
});

export function WindowProvider({ children }) {
  const [hash, setHash] = useState('');
  const value = { hash };

  // Set Defaults and Window Listeners
  useEffect(() => {
    // Hash
    const hashId = getHash();

    window.addEventListener('hashchange', () => setHash(getHash()), false);

    if (hashId) {
      setHash(hashId);

      const el = document.getElementById(hashId);

      el && el.scrollIntoView({ behavior: 'smooth' });
    }

    globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        setTimeout(() => (document.body.scrollTop = 0));
      }
    });
  }, []);

  return <WindowContext.Provider value={value}>{children}</WindowContext.Provider>;
}

export default WindowContext;
