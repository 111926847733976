import React, { useState, useEffect, useRef } from 'react';
import { css } from 'linaria';
import { Toast, Portal } from 'mediasoil-shared-components/components/';
import cls from 'mediasoil-shared-components/utils/class_strings';
import CloseIcon from 'Img/close.svg';
import SuccessIcon from 'Img/success.svg';
import ErrorIcon from 'Img/error.svg';
import NoteIcon from 'Img/note.svg';

const icons = {
  info: NoteIcon,
  error: ErrorIcon,
  success: SuccessIcon,
};

const styles = {
  base: css`
    align-items: center;
    animation: toast map-get(helpers.$transition, menu);
    animation-fill-mode: both;
    background: white;
    border-radius: 4px;
    box-shadow: 1px 7px 14px -5px rgba(0, 0, 0, 0.2);
    display: grid;
    font-size: map-get(helpers.$font-sizes, input);
    grid-template-columns: auto 1fr;
    grid-template-rows: auto auto;
    padding: helpers.$gutter * 1.25;
    position: relative;
    width: 100%;

    > header {
      font-weight: 700;
      margin-bottom: helpers.$gutter/8;
      grid-column: 3;
      grid-row: 1;
    }
  `,
  childcnt: css`
    font-weight: 300;
    grid-column: 3;
    grid-row: 2;

    a {
      font-weight: 400;
    }
  `,
  icon: css`
    $size: 2.5em;

    color: map-get(helpers.$colors, info);
    display: block;
    grid-column: 1;
    grid-row: 1 / 3;
    height: $size;
    margin-right: helpers.$gutter;
    position: relative;
    width: $size;
  `,
  hr: css`
    background: map-get(helpers.$colors, action4);
    border: 0;
    grid-column: 2;
    grid-row: 1 / 3;
    height: calc(100% + #{helpers.$gutter/2});
    margin-right: helpers.$gutter;
    width: 1px;
  `,
  'icon-error': css`
    color: map-get(helpers.$colors, error);
  `,
  'icon-success': css`
    color: map-get(helpers.$colors, success);
  `,
  closeBtn: css`
    background: white;
    border: 0;
    border-radius: 50%;
    color: map-get(helpers.$colors, action2);
    cursor: pointer;
    height: 2em;
    padding: 0;
    position: absolute;
    right: helpers.$gutter/4;
    top: helpers.$gutter/4;
    transition: all map-get(helpers.$transition, default);
    width: 2em;

    &:hover,
    &:focus {
      background: map-get(helpers.$colors, action4);
    }

    svg {
      height: 0.9em;
      margin: auto;
      width: 0.9em;
    }
  `,
  progress: css`
    $color: map-get(helpers.$colors, info);

    appearance: none;
    background: rgba($color, 0.15);
    border: none;
    border-radius: 0;
    bottom: 0;
    color: $color;
    height: 3px;
    left: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    width: 100%;

    &::before {
      animation: progress ease-out;
      animation-duration: inherit;
      background: currentColor;
      content: '';
      display: block;
      height: inherit;
      width: 100%;
      will-change: width;
    }
  `,
  'progress-error': css`
    $color: map-get(helpers.$colors, error);

    background: rgba($color, 0.15);
    color: $color;
  `,
  'progress-success': css`
    $color: map-get(helpers.$colors, success);

    background: rgba($color, 0.15);
    color: $color;
  `,
};

const CloseButton = (props) => (
  <button className={styles.closeBtn} type="button" {...props}>
    <svg>
      <use xlinkHref={`#${CloseIcon.id}`} />
    </svg>
  </button>
);

export default ({ children, className, timer, type, closeModal, ...props }) => {
  const [showPortal, setShowPortal] = useState(null);
  const toastRef = useRef(null);

  useEffect(() => setShowPortal(true), []);
  useEffect(() => toastRef?.current?.scrollIntoView?.({ behavior: 'smooth' }), [toastRef]);

  return (
    showPortal && (
      <Portal id="toast">
        <Toast
          {...props}
          className={cls(className, styles.base)}
          close={<CloseButton onClick={closeModal} />}
          onClose={closeModal}
          ref={toastRef}
          timer={timer}
        >
          <svg aria-hidden="true" className={cls(styles.icon, styles[`icon-${type}`])}>
            <use xlinkHref={`#${(icons[type] || icons.info).id}`} />
          </svg>
          <hr className={styles.hr} />
          <div className={styles.childcnt}>{children}</div>
          {timer && (
            <div
              className={cls(styles.progress, styles[`progress-${type}`])}
              style={{ animationDuration: `${timer}ms` }}
            />
          )}
        </Toast>
      </Portal>
    )
  );
};
