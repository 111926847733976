import React from 'react';
import { css } from 'linaria';
import { Breadcrumb, Hero, Main } from 'mediasoil-shared-components/components/';
import cls from 'mediasoil-shared-components/utils/class_strings';
import mainFunctions from '../../utils/mainFunctions';
import CTA from '../cta';
import Form from '../form';
import Textarea from '../textarea';
import Input from '../input';
import Accordion from '../accordion';
import ServiceSquares from '../service-squares';
import Testimonials from '../testimonials';
import LatestPosts from '../latestposts';
import TitleBG from 'Img/page-title.svg';

const styles = {
  section: css`
    display: flex;
    flex-direction: column;

    @include helpers.mq(page-heading-image-section-min) {
      display: grid;
      grid-template-columns: 1fr 55.8%;
      grid-template-rows: auto auto 1fr;
      min-height: 50vw;
    }

    @include helpers.mq(page-heading-image-section-max) {
      margin-bottom: helpers.$gutter;
      min-height: 60rem;
    }
  `,
  breadcrumb: css`
    font-size: map-get(helpers.$font-sizes, h5);
    grid-column: 1;
    grid-row: 1;
    letter-spacing: 0.15em;
    margin: helpers.$gutter 0;
    order: -2;

    @include helpers.mq(page-heading-image-section-min) {
      margin: helpers.$gutter * 2 0;
    }

    a {
      color: map-get(helpers.$colors, action1);
      font-weight: bold;
      margin: 0 helpers.$gutter/2;
      overflow: hidden;
      text-decoration: underline;
      text-overflow: ellipsis;
      text-transform: uppercase;
      white-space: nowrap;

      &:visited {
        color: map-get(helpers.$colors, action1);
      }

      &:hover {
        color: map-get(helpers.$colors, linkFocus);
      }

      &[aria-current='true'] {
        font-weight: 300;
        text-decoration: none;
      }
    }

    li {
      $widthOfDivider: 3px;

      align-items: center;
      display: flex;
      margin-bottom: helpers.$gutter/2;

      &::before,
      &::after {
        border-radius: $widthOfDivider;
        content: '';
        display: inline-block;
        height: 100%;
        margin: 0 $widthOfDivider/2;
        order: -1;
        transform: rotate(35deg);
        width: $widthOfDivider;
      }

      &::before {
        background: map-get(helpers.$colors, brand-orange);
      }

      &::after {
        background: map-get(helpers.$colors, secondary);
      }

      &:first-child {
        &::before,
        &::after {
          content: normal;
        }

        a {
          margin-left: 0;
        }
      }

      &:last-child {
        a {
          margin-right: 0;
        }
      }
    }
  `,
  content: css`
    align-items: center;
    display: flex;
    grid-column: 1;
    grid-row: 2;
    position: relative;

    h1 {
      margin: 0 0 helpers.$gutter;
    }

    li {
      margin-top: 0.5em;
    }

    a[href^='#'] {
      color: currentColor;
      font-weight: bold;
      text-decoration: underline;
    }
  `,
  titleBG: css`
    $size: 24.2rem;

    margin: 0 helpers.$gutter 0 $size/-2;
    order: -1;
    width: $size;
    z-index: map-get(helpers.$z, hidden);

    @include helpers.mq(page-heading-image-section-mid, true) {
      display: none;
    }
  `,
  imagery: css`
    margin-bottom: helpers.$gutter;
    order: -1;
    position: relative;

    @include helpers.mq(page-heading-image-section-min) {
      background: url('/page-hero-v1.svg') top right no-repeat;
      background-size: contain;
      grid-column: 2;
      grid-row: 1 / 4;
      margin: 0;
      transform: translateX(helpers.$gutter);
    }

    &::before {
      @include helpers.mq(page-heading-image-section-min, true) {
        @include helpers.bleed();

        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-91.214 28.207 682.377 443.554'%3E%3Cpath d='M249.993 471.761C61.551 471.798-91.214 250.04-91.214 250.04S61.551 28.207 249.956 28.207c188.441.037 341.207 221.796 341.207 221.796s-152.655 221.796-341.17 221.758' fill='%23#{str-slice(inspect(map-get(helpers.$colors, brand-green)), 2)}'/%3E%3C/svg%3E")
          center no-repeat;
        background-size: 83%;
        content: '';
        transform: translateX(-50%) rotate(-20deg);
      }
    }
  `,
  featuredImageCnt: css`
    margin: helpers.$gutter/2 auto;
    max-height: 15em;
    max-width: 15em;
    position: relative;
    width: 100%;

    &::before {
      content: '';
      display: block;
      padding-top: 100%;
      width: 0;
    }

    @include helpers.mq(page-heading-image-section-min) {
      margin: 0;
      max-width: none;
      padding-top: 60%;
      position: absolute;
      right: 28.5%;
      top: 8%;
      width: 60%;

      &::before {
        content: normal;
      }
    }
  `,
  featuredImage: css`
    border: 5px solid map-get(helpers.$colors, brand-green);
    border-radius: 50%;
    display: block;
    height: 100%;
    left: 0;
    object-fit: cover;
    object-position: center;
    position: absolute;
    top: 0;
    width: 100%;

    @include helpers.mq(page-heading-image-section-min) {
      border: 0;
    }
  `,
  main: css`
    > * {
      margin-left: auto;
      margin-right: auto;
      max-width: helpers.$tubeMaxWidth;
    }
  `,
};

export default ({ breadcrumbs, className, featuredImage, headingContent = '', title, ...rest }) => {
  const components = {
    Accordion,
    Action: CTA,
    Hero,
    Form,
    Input,
    Textarea,
    Servicesquares: ServiceSquares,
    Testimonials,
    Post: LatestPosts,
  };

  headingContent = headingContent || '';

  return (
    <>
      {breadcrumbs && (
        <section className={styles.section}>
          <Breadcrumb className={styles.breadcrumb} label="Breadcrumb" links={breadcrumbs} />
          <div className={styles.content}>
            <Main id={undefined} tagName="div" content={`<h1>${title}</h1>${headingContent}`} components={components} />
            <svg className={styles.titleBG}>
              <use xlinkHref={`#${TitleBG.id}`} />
            </svg>
          </div>
          <div className={styles.imagery}>
            <div className={styles.featuredImageCnt}>
              <img {...featuredImage} className={styles.featuredImage} loading="lazy" />
            </div>
          </div>
        </section>
      )}
      <Main {...rest} className={cls(className, styles.main)} components={components} functions={mainFunctions} />
    </>
  );
};
