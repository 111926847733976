import React, { useState, useRef } from 'react';
import { css } from 'linaria';
import { Form } from 'mediasoil-shared-components/components/';
import cls from 'mediasoil-shared-components/utils/class_strings';
import Loader from '../loader';
import Toast from '../toast';

const styles = {
  cnt: css`
    position: relative;
  `,
  loader: css`
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    z-index: map-get(helpers.$z, base);
  `,
  form: css`
    display: flex;
    flex-direction: column;
    transition: map-get(helpers.$transition, default);
  `,
  'form--loading': css`
    filter: blur(0.5px);
    opacity: 0.5;
    pointer-events: none;
    user-select: none;
  `,
};
const ERROR_STATE = 'error';
const SUCCESS_STATE = 'success';

export default ({ className, onSubmit, ...props }) => {
  const [loading, setLoading] = useState(null);
  const [showToastType, setShowToastType] = useState(null);
  const [key, setKey] = useState(0);
  const promiseRef = useRef(null);
  const successFn = () => setShowToastType(SUCCESS_STATE);
  const errorFn = () => setShowToastType(ERROR_STATE);
  const toastProps =
    SUCCESS_STATE === showToastType
      ? {
          title: 'Thanks for your submission!',
          timer: 10000,
          children: <>We've received your message and will get back to you as soon as we can!</>,
        }
      : {
          title: 'Oofta. Something went wrong.',
          timer: 20000,
          children: (
            <>
              We're very sorry but we couldn't submit this form right now. Please try again! If the issue continues,
              please reach out to <a href="mailto:support@mediasoil.com">support@mediasoil.com</a>.
            </>
          ),
        };

  return (
    <div className={styles.cnt}>
      {loading && <Loader position="absolute" className={styles.loader} />}
      {!loading && showToastType && (
        <Toast type={showToastType} closeModal={() => setShowToastType(null)} {...toastProps} />
      )}
      <Form
        className={cls(className, styles.form, loading && styles['form--loading'])}
        key={key}
        onSubmit={(...args) => {
          setLoading(true);

          // Cancel Existing
          if (promiseRef.current) {
            promiseRef.current[1]();
          }

          // Do Promise
          promiseRef.current = onSubmit(...args);
          promiseRef.current[0]
            .then(({ data }) => {
              if (data.success) {
                setKey(Date.now());
                successFn(data);
              } else {
                errorFn(data);
              }
            })
            .catch(errorFn)
            .finally(() => setLoading(false));
        }}
        {...props}
      />
    </div>
  );
};
