import React from 'react';
import PropTypes from 'prop-types';
import { css } from 'linaria';
import cls from 'mediasoil-shared-components/utils/class_strings';
import TreeIcon from 'Img/tree.svg';

const styles = {
  base: css`
    height: 6.4375em;
    position: relative;
    transform: rotate(-25deg);
    width: 4.0625em;
  `,
  primary: css`
    color: map-get(helpers.$colors, brand-green);
    fill: #5f4b20;
  `,
  secondary: css`
    color: map-get(helpers.$colors, complement-green);
    fill: map-get(helpers.$colors, brand-orange);
  `,
};

const forestStyle = css`
  display: grid;
  grid-column-gap: 3em;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  height: 12.875em;
  position: relative;
  transform: rotate(25deg);
  width: 18.1875em;

  > *:nth-child(-n + 3) {
    left: 100%;
  }
`;

const Trees = ({ className, isForest, style = 'primary', ...DOM }) => {
  return (
    <div aria-hidden="true" className={cls(className, isForest && forestStyle)} {...DOM}>
      {isForest ? (
        [1, 0, 1, 0, 1, 0].map((type, i) => (
          <svg className={cls(className, styles.base, styles[type === 0 ? 'primary' : 'secondary'])} key={i}>
            <use xlinkHref={`#${TreeIcon.id}`} />
          </svg>
        ))
      ) : (
        <svg className={cls(styles.base, styles[style])}>
          <use xlinkHref={`#${TreeIcon.id}`} />
        </svg>
      )}
    </div>
  );
};

Trees.propTypes = {
  className: PropTypes.string,
  isForest: PropTypes.bool,
  style: PropTypes.string,
};

export default Trees;
